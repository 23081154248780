<template>
  <div>
    <a-modal
      :visible="visible.modalAddPegawai"
      title="Buat Pegawai Baru"
      okText="Buat"
      cancelText="Kembali"
      @ok="handleCreatePegawai"
      @cancel="handleCancel"
      :okButtonProps="{
        props: {
          disabled: loading.add,
        },
      }"
      :cancelButtonProps="{
        props: {
          disabled: loading.add,
        },
      }"
    >
      <div
        v-if="loading.add"
        style="
          display: flex;
          justify-content: center;
          height: 30vh;
          align-items: center;
        "
      >
        <a-spin>
          <a-icon
            slot="indicator"
            type="loading"
            style="font-size: 5em; color: #008080"
            spin
          />
        </a-spin>
      </div>
      <a-form
        v-else
        layout="horizontal"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 16 }"
      >
        <a-form-item label="Nama">
          <a-input
            :value="dataPegawai.namaPegawai"
            :disabled="userAdmin.finance"
            size="large"
            :placeholder="
              userAdmin.hr ? 'Nama Pegawai' : 'bisa di input di admin hr'
            "
            @change="
              (e) => handleAddPegawaiChange(e.target.value, 'namaPegawai')
            "
          />
        </a-form-item>
        <a-form-item label="NIR">
          <a-input
            :value="dataPegawai.nir"
            :disabled="userAdmin.finance"
            size="large"
            :placeholder="
              userAdmin.hr ? 'NIR Pegawai' : 'bisa di input di admin hr'
            "
            @change="(e) => handleAddPegawaiChange(e.target.value, 'nir')"
          />
          <!-- <small>*Password awal pegawai akan menyesuaikan dengan NIR pegawai</small> -->
        </a-form-item>
        <a-form-item label="Jenis Kelamin">
          <a-select
            :value="dataPegawai.jenis_kelamin"
            size="large"
            style="width: 100%; height: 40px"
            @change="handleSelectJenisKelamin"
            placeholder="Pilih Jenis Kelamin"
          >
            <a-select-option
              v-for="item in jenisKelamin"
              :key="item"
              >{{ item }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="Unit">
          <a-select
            size="large"
            style="width: 100%; height: 40px"
            @change="handleSelectUnit"
            placeholder="Select Unit"
          >
            <a-select-option
              v-for="item in list.unit"
              :key="item.id"
              :value="item.id"
              >{{ item.nama }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="Jabatan">
          <a-select
            :allowClear="true"
            v-model="selected.jabatan.name"
            mode="multiple"
            :disabled="!selected.unit.status"
            size="large"
            style="width: 100%; height: 40px"
            @change="handleSelectJabatan"
            placeholder="Select Jabatan"
          >
            <a-select-option
              v-for="item in filterJabatan"
              :key="item.id"
              >{{ item.nama }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="Golongan">
          <a-select
            :disabled="!selected.jabatan.status"
            size="large"
            style="width: 100%; height: 40px"
            @change="handleSelectGolongan"
            placeholder="Select Golongan"
          >
            <a-select-option
              v-for="item in list.golongan"
              :key="item.id"
              :value="item.nama"
              >{{ item.nama }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="Marital">
          <a-select
            :disabled="!selected.golongan.status"
            size="large"
            style="width: 100%; height: 40px"
            @change="handleSelectMarital"
            placeholder="Select Marital"
          >
            <a-select-option
              v-for="item in list.marital"
              :key="item.id"
              :value="item.status_marital"
              >{{ item.status_marital }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="Status">
          <a-select
            :disabled="!selected.marital.status"
            size="large"
            style="width: 100%; height: 40px"
            @change="handleSelectStatusPegawai"
            placeholder="Select Status Pegawai"
          >
            <a-select-option
              v-for="item in list.statusPegawai"
              :key="item.key"
              :value="item.value"
              >{{ item.value }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="Bergabung">
          <a-date-picker class="w-100" size="large" @change="handleTanggalBergabung" placeholder="Pilih Tanggal" />
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal
      :visible="visible.modalEditPegawai"
      :confirm-loading="loading.confirm"
      title="Ubah Tunjangan Pegawai"
      okText="Perbaharui"
      cancelText="Kembali"
      @ok="handleOkEditPegawai"
      @cancel="handleCancel"
      :okButtonProps="{
        props: {
          disabled: loading.edit,
        },
      }"
      :cancelButtonProps="{
        props: {
          disabled: loading.edit,
        },
      }"
    >
      <div
        v-if="loading.edit"
        style="
          display: flex;
          justify-content: center;
          height: 30vh;
          align-items: center;
        "
      >
        <a-spin>
          <a-icon
            slot="indicator"
            type="loading"
            style="font-size: 5em; color: #008080"
            spin
          />
        </a-spin>
      </div>
      <a-form
        v-else
        layout="horizontal"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 16 }"
      >
        <a-form-item label="Nama">
          <a-input
            :value="dataPegawai.namaPegawai"
            :disabled="userAdmin.finance"
            size="large"
            :placeholder="
              userAdmin.hr ? 'Nama Pegawai' : 'bisa di input di admin hr'
            "
            @change="
              (e) => handleEditPegawaiChange(e.target.value, 'namaPegawai')
            "
          />
        </a-form-item>
        <a-form-item label="NIR">
          <a-input
            :value="dataPegawai.nir"
            :disabled="userAdmin.finance"
            size="large"
            :placeholder="
              userAdmin.hr ? 'NIR Pegawai' : 'bisa di input di admin hr'
            "
            @change="(e) => handleEditPegawaiChange(e.target.value, 'nir')"
          />
        </a-form-item>
        <a-form-item label="Jenis Kelamin">
          <a-select
            :value="dataPegawai.jenis_kelamin"
            size="large"
            style="width: 100%; height: 40px"
            @change="handleSelectJenisKelamin"
            placeholder="Pilih Jenis Kelamin"
          >
            <a-select-option
              v-for="item in jenisKelamin"
              :key="item"
              >{{ item }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="Unit">
          <a-select
            v-model="dataPegawai.unit"
            size="large"
            style="width: 100%; height: 40px"
            @change="handleSelectUnit"
            placeholder="Select Unit"
          >
            <a-select-option
              v-for="item in list.unit"
              :key="item.id"
              :value="item.id"
              >{{ item.nama }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="Jabatan">
          <a-select
            :allowClear="true"
            v-model="selected.jabatan.name"
            mode="multiple"
            :disabled="!selected.unit.status"
            size="large"
            style="width: 100%; height: 40px"
            @change="handleSelectJabatan"
            placeholder="Select Jabatan"
          >
            <a-select-option
              v-for="item in filterJabatan"
              :key="item.id"
              >{{ item.nama }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="Golongan">
          <a-select
            :default-value="dataPegawai.golonganName"
            size="large"
            style="width: 100%; height: 40px"
            @change="handleSelectGolongan"
            placeholder="Select Golongan"
          >
            <a-select-option
              v-for="item in list.golongan"
              :key="item.id"
              :value="item.nama"
              >{{ item.nama }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="Marital">
          <a-select
            :default-value="dataPegawai.statusMaritalName"
            size="large"
            style="width: 100%; height: 40px"
            @change="handleSelectMarital"
            placeholder="Select Marital"
          >
            <a-select-option
              v-for="item in list.marital"
              :key="item.id"
              :value="item.status_marital"
              >{{ item.status_marital }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="Status">
          <a-select
            :default-value="dataPegawai.statusPegawai"
            size="large"
            style="width: 100%; height: 40px"
            @change="handleSelectStatusPegawai"
            placeholder="Select Status Pegawai"
          >
            <a-select-option
              v-for="item in list.statusPegawai"
              :key="item.key"
              :value="item.value"
              >{{ item.value }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="Bergabung">
          <a-date-picker v-model="dataPegawai.tanggalBergabung" class="w-100" size="large" @change="handleTanggalBergabung" placeholder="Pilih Tanggal" />
        </a-form-item>
      </a-form>
    </a-modal>
    <a-collapse class="mb-4">
      <a-collapse-panel header="Perhatian!" icon="info-circle">
        <ul>
          <li>Jika pilihan UNIT tidak muncul saat menambah / mengubah data pegawai, hal ini disebabkan karena belum ada JABATAN di UNIT tersebut. Mohon membuat JABATAN untuk UNIT tersebut terlebih dahulu</li>
          <li>Mohon mengisi semua master data: UNIT, GOLONGAN, JABATAN secara lengkap dan berurutan agar proses pengisian data PEGAWAI berjalan lancar.</li>
        </ul>
      </a-collapse-panel>
    </a-collapse>
    <div class="d-flex align-items center">
      <div class="mr-3">
        <a-input
          v-model="pegawaiSearch"
          @keyup="searchPegawai"
          size="large"
          style="width: 320px"
          :placeholder="`Cari Nama Pegawai...`"
        />
      </div>
      <a-select
        class="mr-3"
        v-model="filter.by"
        @change="key => handleFilter(key, 'filterBy')"
        size="large"
      >
        <a-select-option
          value="all"
          >Semua Data</a-select-option
        >
        <a-select-option
          v-for="item in listFilter"
          :key="item.key"
          :value="item.key"
          >{{item.nama}}</a-select-option
        >
      </a-select>
      <a-select
        v-model="filter.params"
        @change="key => handleFilter(key, 'filterParams')"
        v-if="filter.by !== 'all'"
        size="large"
      >
        <a-select-option
          value="all"
          >Semua Data</a-select-option
        >
        <a-select-option
          v-for="item in listFilterParams"
          :key="item.key"
          :value="item.key"
          >{{item.nama}}</a-select-option
        >
      </a-select>
      <div class="ml-auto">
        <template>
          <a-button
            :disabled="edit.pegawai"
            class="mr-3"
            size="large"
            type="primary"
            @click.prevent="showModalAddPegawai(true)"
            icon="plus"
            >Tambah</a-button
          >
        </template>
      </div>
    </div>
    <div class="mt-4">
      <a-table
        :columns="pegawaiColumns"
        :data-source="pegawaiDataTable"
        :loading="loading.table"
        :pagination="pagination"
        :total="pagination.total"
        :scroll="{ x: 1150 }"
        @change="handleTableChangePegawai"
        bordered
      >
        <div slot="jabatan" slot-scope="value, record">
          <template v-if="!record.jabatan.length">-</template>
          <template v-else>
            <a-tag
                v-for="obj in record.jabatan" :key="obj.id"
              >
              {{obj}}
            </a-tag>
          </template>
        </div>
        <div slot="shift" slot-scope="text, record">
          <div v-if="record.shift">{{record.shift.nama}}</div>
          <div v-else>-</div>
        </div>
        <div slot="nullChecker" slot-scope="value">
          {{value || '-'}}
        </div>
        <div
          v-if="userAdmin.hr"
          slot="aksi"
          slot-scope="text, record"
          class="d-flex justify-content-around"
        >
          <a-button
            class="text-warning border border-warning"
            :value="text"
            :loading="loading.edit"
            icon="edit"
            @click.prevent="(e) => showModalEditPegawai(record.key, true)"
          ></a-button>
          <a-button
            class="text-danger border border-danger"
            :disabled="edit.pegawai"
            :value="text"
            :loading="loading.delete"
            icon="delete"
            @click.prevent="(e) => deletePegawai(record.key)"
          ></a-button>
        </div>
      </a-table>
    </div>
  </div>
</template>

<script>
const pegawaiColumns = [
  {
    title: 'Nama Pegawai',
    dataIndex: 'namaPegawai',
    key: 'namaPegawai',
    align: 'left',
    fixed: 'left',
    width: 300,
    scopedSlots: { customRender: 'namaPegawai' },
    sorter: true,
  },
  {
    title: 'NIR',
    dataIndex: 'nir',
    key: 'nir',
    align: 'center',
    // fixed: 'left',
    width: 120,
    scopedSlots: { customRender: 'nir' },
    sorter: true,
  },
  {
    title: 'Jenis Kelamin',
    dataIndex: 'jenisKelamin',
    key: 'jenisKelamin',
    align: 'center',
    // fixed: 'left',
    width: 120,
    scopedSlots: { customRender: 'nullChecker' },
  },
  {
    title: 'Shift',
    dataIndex: 'shift',
    key: 'shift',
    align: 'center',
    width: 200,
    scopedSlots: { customRender: 'shift' },
  },
  {
    title: 'Unit',
    dataIndex: 'unit',
    key: 'unit',
    align: 'center',
    width: 200,
    scopedSlots: { customRender: 'nullChecker' },
  },
  {
    title: 'Jabatan',
    dataIndex: 'jabatan',
    key: 'jabatan',
    align: 'center',
    width: 200,
    scopedSlots: { customRender: 'jabatan' },
  },
  {
    title: 'Golongan',
    dataIndex: 'golongan',
    key: 'golongan',
    align: 'center',
    width: 200,
    scopedSlots: { customRender: 'nullChecker' },
  },
  {
    title: 'Status Marital',
    dataIndex: 'statusMarital',
    key: 'statusMarital',
    align: 'center',
    width: 200,
    scopedSlots: { customRender: 'nullChecker' },
  },
  {
    title: 'Status Pegawai',
    dataIndex: 'statusPegawai',
    key: 'statusPegawai',
    align: 'center',
    width: 200,
    scopedSlots: { customRender: 'nullChecker' },
    sorter: true,
  },
  {
    title: 'Tanggal Bergabung',
    dataIndex: 'tanggalBergabung',
    key: 'tanggalBergabung',
    align: 'center',
    width: 200,
    scopedSlots: { customRender: 'nullChecker' },
    sorter: true,
  },
  {
    title: 'Aksi',
    dataIndex: 'aksi',
    key: 'aksi',
    scopedSlots: { customRender: 'aksi' },
    width: 120,
    align: 'center',
    fixed: 'right',
  },
]

export default {
  name: 'master-pegawai',
  data() {
    return {
      pegawaiColumns,
      pegawaiDataTable: [],
      savedPegawaiData: [],
      visible: {
        modalAddPegawai: false,
        modalEditPegawai: false,
      },
      loading: {
        table: false,
        add: false,
        edit: false,
        delete: false,
        cancel: false,
        confirm: false,
      },
      listFilterParams: [],
      listFilter: [{
        key: 'id_golongan',
        nama: 'Golongan',
      }, {
        key: 'id_jabatan',
        nama: 'Jabatan',
      }, {
        key: 'id_marital',
        nama: 'Marital',
      }, {
        key: 'id_unit',
        nama: 'Unit',
      }, {
        key: 'id_shift',
        nama: 'Shift',
      }],
      filter: {
        by: 'all',
        params: 'all',
      },
      pagination: {},
      filterJabatan: [],
      userEditId: '',
      selected: {
        unit: {
          id: '',
          name: '',
          status: false,
        },
        jabatan: {
          id: [],
          id_unit: '',
          name: [],
          status: false,
        },
        golongan: {
          id: '',
          name: '',
          status: false,
        },
        marital: {
          id: '',
          name: '',
          status: false,
        },
      },
      pegawaiPage: 'all',
      pegawaiSearch: '',
      pegawaiOrder: '',
      pegawaiSortBy: '',
      dataPegawai: {
        namaPegawai: null,
        nir: null,
        unit: null,
        jenis_kelamin: null,
        unitName: null,
        jabatan: [],
        jabatanName: [],
        golongan: null,
        golonganName: null,
        statusMarital: null,
        statusMaritalName: null,
        statusPegawai: null,
        tanggalBergabung: null,
      },
      edit: {
        pegawai: false,
      },
      jenisKelamin: ['Laki-Laki', 'Perempuan'],
      userAdmin: {
        finance: false,
        hr: false,
      },
      list: {
        unit: [],
        jabatan: [],
        golongan: [],
        marital: [],
        shift: [],
        statusPegawai: [
          {
            key: 1,
            value: 'tetap',
          },
          {
            key: 2,
            value: 'kontrak',
          },
          {
            key: 3,
            value: 'honorer',
          },
          {
            key: 4,
            value: 'orientasi',
          },
        ],
      },
    }
  },
  methods: {
    handleFilter(val, sign) {
      if (sign === 'filterBy') {
        this.filter = {
          by: val,
          params: 'all',
        }
        if (val !== 'all') {
          const text = val.split('_')[1] // memisahkan id_ pada value
          this.listFilterParams = this.list[text.toLowerCase()].map(data => {
            return {
              key: data.id,
              nama: text === 'marital' ? data.status_marital : data.nama,
            }
          })
        }
      } else if (sign === 'filterParams') this.filter.params = val
      this.fetchDataPegawai({
        search: this.pegawaiSearch,
      })
    },
    handleEditPegawaiChange(value, column) {
      if (column === 'namaPegawai') {
        this.dataPegawai.namaPegawai = value
      }
      if (column === 'nir') {
        this.dataPegawai.nir = value
      }
    },
    handleTableChangePegawai(pagination, filters, sorter) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      let order = ''
      if (sorter.order === 'ascend') {
        order = 'ASC'
      } else if (sorter.order === 'descend') {
        order = 'DESC'
      } else {
        order = 'ASC'
      }
      let sortBy = ''
      if (sorter.field === 'namaPegawai') {
        sortBy = 'nama'
      } else if (sorter.field === 'nir') {
        sortBy = 'nik'
      } else if (sorter.field === 'statusPegawai') {
        sortBy = 'status_pegawai'
      } else {
        sortBy = ''
      }
      this.fetchDataPegawai({
        page: pagination.current,
        order,
        sortBy,
        selectedUnitId: this.selected.unit.id,
      })
    },
    handleAllLoading(boolean) {
      this.loading.table = boolean
      this.loading.add = boolean
      this.loading.edit = boolean
      this.loading.delete = boolean
      this.loading.cancel = boolean
    },
    handleEmptyingModalData() {
      this.selected.unit.id = ''
      this.selected.unit.name = ''
      this.selected.unit.status = false
      this.filterJabatan = []
      this.selected.jabatan.id = []
      this.selected.unit.id_unit = ''
      this.selected.jabatan.name = []
      this.selected.jabatan.status = false
      this.selected.golongan.id = ''
      this.selected.golongan.name = ''
      this.selected.golongan.status = false
      this.selected.marital.id = ''
      this.selected.marital.name = ''
      this.selected.marital.status = false
      this.userEditId = ''
      this.dataPegawai.namaPegawai = ''
      this.dataPegawai.nir = ''
      this.dataPegawai.unit = ''
      this.dataPegawai.unitName = ''
      this.dataPegawai.jabatan = []
      this.dataPegawai.jabatanName = []
      this.dataPegawai.golongan = ''
      this.dataPegawai.golonganName = ''
      this.dataPegawai.statusMarital = ''
      this.dataPegawai.statusMaritalName = ''
      this.dataPegawai.statusPegawai = ''
      this.dataPegawai.tanggalBergabung = ''
    },
    showModalAddPegawai(boolean) {
      this.visible.modalAddPegawai = boolean
    },
    showModalEditPegawai(id, boolean) {
      this.handleAllLoading(true)
      this.$store
        .dispatch('adminHR/GET_PEGAWAI_BY_ID', { id })
        .then((res) => {
          const data = res.data
          // console.log('data', data)
          this.userEditId = data.id
          this.dataPegawai.namaPegawai = data.namaPegawai
          this.dataPegawai.jenis_kelamin = data.jenisKelamin
          this.dataPegawai.nir = data.nir
          this.dataPegawai.unit = data.idUnit
          this.dataPegawai.jabatan = data.arrIdJabatan
          this.dataPegawai.jabatanName = data.arrNamaJabatan
          this.dataPegawai.golongan = data.idGolongan
          this.dataPegawai.golonganName = data.namaGolongan
          this.dataPegawai.statusMarital = data.idMarital
          this.dataPegawai.statusMaritalName = data.statusMarital
          this.dataPegawai.statusPegawai = data.statusPegawai
          this.dataPegawai.tanggalBergabung = data.tanggalBergabung || null
          this.handleAllLoading(false)
          this.visible.modalEditPegawai = boolean
          this.edit.pegawai = boolean
        })
    },
    searchPegawai() {
      if (this.pegawaiSearch.length > 2) {
        this.fetchDataPegawai({
          search: this.pegawaiSearch,
        })
      } else {
        this.fetchDataPegawai({
          search: this.pegawaiSearch,
        })
      }
    },
    fetchDataPegawai(
      params = {
        order: this.pegawaiOrder,
        sortBy: this.pegawaiSortBy,
        search: this.pegawaiSearch,
        page: this.pegawaiPage,
        selectedUnitId: this.selected.unit.id,
      },
    ) {
      this.handleAllLoading(true)
      const filterBy = this.filter.by === 'all' ? null : this.filter.by
      const filterParams = this.filter.by === 'all' ? null : this.filter.params
      this.$store
        .dispatch('adminHR/GET_PEGAWAI_BY_SITUATION', {
          order: params.order,
          sortBy: params.sortBy,
          search: params.search,
          page: params.page,
          selectedUnitId: params.selectedUnitId,
          filterBy,
          filterParams,
        })
        .then((res) => {
          // console.log('res', res)
          const pagination = { ...this.pagination }
          pagination.total = res.total
          this.pagination = pagination
          this.list.unit = res.listUnit
          this.list.jabatan = res.listJabatan
          this.list.golongan = res.listGolongan
          this.list.marital = res.listMarital
          this.list.shift = res.listShift
          this.pegawaiDataTable = res.data.map((row) => {
            const arrNamaJabatan = row.arrNamaJabatan || []
            // let jabatan = ''
            // if (arrNamaJabatan.length) {
            //   jabatan = arrNamaJabatan.join(', ')
            // }
            return {
              key: row.id,
              namaPegawai: row.namaPegawai || '',
              nir: row.nir || '',
              idUnit: row.idUnit || '',
              unit: row.namaUnit || '',
              arrIdJabatan: row.arrIdJabatan || [],
              arrNamaJabatan,
              jabatan: arrNamaJabatan,
              idGolongan: row.idGolongan || '',
              golongan: row.namaGolongan || '',
              statusMarital: row.statusMarital || '',
              statusPegawai: row.statusPegawai || '',
              tanggalBergabung: row.tanggalBergabung || '',
              jenisKelamin: row.jenisKelamin || '',
              shift: row.shift || '',
            }
          })
          // console.log('this.pegawaiDataTable', this.pegawaiDataTable)
          this.savedPegawaiData = this.pegawaiDataTable
          this.handleAllLoading(false)
        })
    },
    handleCancel() {
      this.visible.modalAddPegawai = false
      this.visible.modalEditPegawai = false
      this.edit.pegawai = false
      this.handleEmptyingModalData()
    },
    handleAddPegawaiChange(value, column) {
      if (column === 'namaPegawai') {
        this.dataPegawai.namaPegawai = value
      }
      if (column === 'nir') {
        this.dataPegawai.nir = value
      }
    },
    handleCreatePegawai() {
      this.handleAllLoading(true)
      if (this.dataPegawai.namaPegawai === '' || this.dataPegawai.namaPegawai === null) {
        this.$notification.error({
          message: 'Error',
          description: 'NAMA pegawai tidak boleh kosong!',
        })
        this.handleAllLoading(false)
        this.visible.modalAddPegawai = false
      } else if (this.dataPegawai.nir === '' || this.dataPegawai.nir === null) {
        this.$notification.error({
          message: 'Error',
          description: 'NIR pegawai tidak boleh kosong!',
        })
        this.handleAllLoading(false)
        this.visible.modalAddPegawai = false
      } else if (isNaN(Number(this.dataPegawai.nir))) {
        this.$notification.error({
          message: 'Error',
          description: 'NIR pegawai harus angka & tidak boleh sama dengan pegawai lain!',
        })
        this.handleAllLoading(false)
        this.visible.modalAddPegawai = false
      } else if (this.dataPegawai.unit === '' || this.dataPegawai.unit === null) {
        this.$notification.error({
          message: 'Error',
          description: 'UNIT pegawai tidak boleh kosong!',
        })
        this.handleAllLoading(false)
        this.visible.modalAddPegawai = false
      } else if (this.dataPegawai.jabatan === '' || this.dataPegawai.jabatan === null) {
        this.$notification.error({
          message: 'Error',
          description: 'JABATAN pegawai tidak boleh kosong!',
        })
        this.handleAllLoading(false)
        this.visible.modalAddPegawai = false
      } else if (this.dataPegawai.golongan === '' || this.dataPegawai.golongan === null) {
        this.$notification.error({
          message: 'Error',
          description: 'GOLONGAN pegawai tidak boleh kosong!',
        })
        this.handleAllLoading(false)
        this.visible.modalAddPegawai = false
      } else if (this.dataPegawai.statusMarital === '' || this.dataPegawai.statusMarital === null) {
        this.$notification.error({
          message: 'Error',
          description: 'STATUS MARITAL pegawai tidak boleh kosong!',
        })
        this.handleAllLoading(false)
        this.visible.modalAddPegawai = false
      } else if (this.dataPegawai.statusPegawai === '' || this.dataPegawai.statusPegawai === null) {
        this.$notification.error({
          message: 'Error',
          description: 'STATUS pegawai tidak boleh kosong!',
        })
        this.handleAllLoading(false)
        this.visible.modalAddPegawai = false
      } else if (this.dataPegawai.tanggalBergabung === '' || this.dataPegawai.tanggalBergabung === null) {
        this.$notification.error({
          message: 'Error',
          description: 'TANGGAL BERGABUNG pegawai tidak boleh kosong!',
        })
        this.handleAllLoading(false)
        this.visible.modalAddPegawai = false
      } else {
        this.$store
          .dispatch('adminHR/CREATE_PEGAWAI', {
            data: {
              namaPegawai: this.dataPegawai.namaPegawai,
              nir: this.dataPegawai.nir,
              idUnit: this.dataPegawai.unit,
              jabatanBundle: this.dataPegawai.jabatan,
              idGolongan: this.dataPegawai.golongan,
              idMarital: this.dataPegawai.statusMarital,
              statusPegawai: this.dataPegawai.statusPegawai,
              tanggalBergabung: this.dataPegawai.tanggalBergabung,
              jenisKelamin: this.dataPegawai.jenis_kelamin,
            },
          })
          .then(res => {
            this.fetchDataPegawai({
              search: this.pegawaiSearch,
            })
            if (res.status === 200) {
              setTimeout(() => {
                this.$notification.success({
                  message: 'Sukses',
                  description: 'Berhasil menambah pegawai baru',
                })
              })
            } else {
              setTimeout(() => {
                this.$notification.error({
                  message: 'Gagal',
                  description: 'Gagal menambah pegawai baru',
                })
              })
            }
            this.handleEmptyingModalData()
            this.handleAllLoading(false)
            this.visible.modalAddPegawai = false
          })
      }
    },
    deletePegawai(key) {
      this.handleAllLoading(true)
      this.$confirm({
        title: 'Peringatan!!',
        content: (
          <div>
            Apakah anda yakin ingin menghapus data pegawai ini? Setelah
            menghapus, data pegawai ini akan hilang!
          </div>
        ),
        onOk: () => {
          this.$store
            .dispatch('adminHR/DELETE_PEGAWAI', {
              id: key,
            })
            .then((res) => {
              this.handleAllLoading(false)
              if (res === 200) {
                this.$notification.success({
                  message: 'Sukses',
                  description: 'Pegawai berhasil dihapus',
                })
              } else {
                this.$notification.error({
                  message: 'Error',
                  description: 'Pegawai tidak berhasil dihapus',
                })
              }
              this.fetchDataPegawai({
                search: this.pegawaiSearch,
              })
            })
        },
        onCancel: () => {
          this.handleAllLoading(false)
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Remove',
      })
    },
    cancelEdit() {
      this.handleAllLoading(true)
      this.fetchDataPegawai({
        search: this.pegawaiSearch,
      })
      setTimeout(() => {
        this.edit.pegawai = false
        this.handleAllLoading(false)
      })
    },
    handleOkEditPegawai() {
      this.$store
        .dispatch('adminHR/UPDATE_PEGAWAI', {
          data: {
            namaPegawai: this.dataPegawai.namaPegawai,
            nir: this.dataPegawai.nir,
            idUnit: this.dataPegawai.unit,
            jabatanBundle: this.dataPegawai.jabatan,
            idGolongan: this.dataPegawai.golongan,
            idMarital: this.dataPegawai.statusMarital,
            statusPegawai: this.dataPegawai.statusPegawai,
            tanggalBergabung: this.dataPegawai.tanggalBergabung,
            jenisKelamin: this.dataPegawai.jenis_kelamin,
          },
          id: this.userEditId,
        })
        .then((res) => {
          // console.log('res', res)
          this.handleEmptyingModalData()
          setTimeout(() => {
            this.fetchDataPegawai({
              search: this.pegawaiSearch,
            })
            this.edit.pegawai = false
            this.visible.modalEditPegawai = false
            this.handleAllLoading(false)
            if (res.isSuccess) {
              this.$notification.success({
                message: 'Sukses',
                description: 'Pegawai berhasil diperbarui',
              })
            } else {
              this.$notification.error(
                {
                  message: 'Gagal',
                  description: 'Pegawai tidak berhasil diperbarui!',
                },
              )
            }
          })
        })
    },
    handleSelectJenisKelamin(key) {
      this.dataPegawai.jenis_kelamin = key
    },
    handleSelectUnit(key, value) {
      if (this.dataPegawai.unit !== '') {
        this.selected.jabatan.id = []
        this.selected.jabatan.name = []
      }
      this.filterJabatan = []
      this.selected.unit.id = value.key
      this.selected.unit.name = key
      const target = this.list.jabatan.filter(
        (el) => el.id_unit === this.selected.unit.id,
      )
      if (target.length) {
        this.filterJabatan = target
        this.selected.unit.status = true
        this.selected.jabatan.id_unit = this.selected.unit.id
        this.dataPegawai.unit = this.selected.unit.id
      }
    },
    handleSelectJabatan(key) {
      let arrJabatans = []
      if (key.length > 1) {
        for (const i in key) {
          const jabatanId = key[i]
          arrJabatans.push(jabatanId)
        }
      } else {
        arrJabatans = key
      }
      this.selected.jabatan.id = arrJabatans
      this.selected.jabatan.status = true
      this.dataPegawai.jabatan = this.selected.jabatan.id
    },
    handleSelectGolongan(key, value) {
      this.selected.golongan.id = value.key
      this.selected.golongan.name = key
      this.selected.golongan.status = true
      this.dataPegawai.golongan = this.selected.golongan.id
    },
    handleSelectMarital(key, value) {
      this.selected.marital.id = value.key
      this.selected.marital.name = key
      this.selected.marital.status = true
      this.dataPegawai.statusMarital = this.selected.marital.id
    },
    handleSelectStatusPegawai(key) {
      this.dataPegawai.statusPegawai = key
    },
    handleTanggalBergabung(dateString) {
      this.dataPegawai.tanggalBergabung = dateString
    },
  },
  mounted() {
    this.fetchDataPegawai()
    if (this.user.role[0] === 'HR') {
      this.userAdmin.hr = true
    } else if (this.user.role[0] === 'keuangan') {
      this.userAdmin.finance = true
      this.pegawaiColumns = this.pegawaiColumns.slice(0, -1)
    } else {
      this.userAdmin.hr = false
      this.userAdmin.finance = false
    }
  },
  computed: {
    user() {
      return this.$store.state.user.user
    },
  },
}
</script>

<style lang="css">
.blackBorder {
  border: 1px solid black;
}
</style>
